import {settings} from './settings'
import {
    // loadSvgSprite,
    blockGlobalScroll,
    unBlockGlobalScroll,
    inputsHighlight
} from './utils'
import objectFitImages from 'object-fit-images'
import AOS from 'aos'
import SmoothScroll from './smooth-scroll.js'
import Swiper from 'swiper'
import Pristine from 'pristinejs'
import initTabs from 'future-tabs'
import swal from 'sweetalert'
import flatpickr from 'flatpickr'
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect'

var initial_celendar;
initTabs('.tabs');

class App {
    constructor() {
        AOS.init({
            disable: 'phone',
            offset: 50
        })

        const smoothScroll = new SmoothScroll('a[href*="#"]', {
            offset: 140
        })

        const partnersSlider = new Swiper(
            '#partnersSlider',
            settings.sliders.partnersSlider
        )

        const fullGallery = new Swiper(
            '#full_gallery',
            settings.sliders.partnersSlider
        )

        const mambersSlider = new Swiper(
            '#membersSlider',
            settings.sliders.partnersSlider
        )

        //   loadSvgSprite()
        inputsHighlight()
        objectFitImages(document.querySelectorAll('.img-fit img'))

        this.addEventListeners()
        this.vhCalc()
        this.initForms()
        this.equalHeight()
    }

    initForms() {
        const forms = document.querySelectorAll('.form')

        forms.forEach(form => {
            const pristine = new Pristine(form, settings.defaultForm)

            form.addEventListener('submit', function (e) {
                e.preventDefault()
                let valid = pristine.validate();

                if (valid) {
                    /* swal({
                       title: 'Thank you!',
                       text: 'We will contact you shortly',
                       button: 'Ok'
                     })

                     */
                }
            })
        })
    }

    addEventListeners() {
        // header state
        if (window.location.pathname === '/') {
            window.addEventListener('scroll', this.headerBg)
        } else {
            let header = document.getElementById('header')
            let main = document.getElementById('main')

            header.classList.add('page-header--on-scroll')
            main.style.paddingTop = header.offsetHeight + 'px'
        }

        // hamburger
        let headerNav = document.getElementById('hamburger-icon')
        if (headerNav) {
            headerNav.addEventListener('click', this.toggleMobileNav)
        }

        // mobile nav list toggle
        let headerMobileLinks = document.querySelectorAll(
            '.page-header__sub-nav-link, .page-header__nav-top-link'
        )
        if (headerMobileLinks) {
            headerMobileLinks.forEach(el =>
                el.addEventListener('click', this.toggleList)
            )
        }

        // update --vh on resize
        window.addEventListener('resize', this.vhCalc)
    }

    headerBg() {
        let header = document.getElementById('header')
        let headerStatus =
            window.pageYOffset >= 50
                ? header.classList.add('page-header--on-scroll')
                : header.classList.remove('page-header--on-scroll')
    }

    vhCalc() {
        let vh = (window.innerHeight - 16) * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    toggleMobileNav(e) {
        const pageHeader = document.querySelector('.page-header')

        e.currentTarget.classList.toggle('nav-icon--is-open')
        pageHeader.classList.toggle('page-header--active-mobile-nav')

        this.isMobileNavOpen = !this.isMobileNavOpen
        this.isMobileNavOpen ? blockGlobalScroll() : unBlockGlobalScroll()
    }

    toggleList(e) {


        const listLink = e.currentTarget
        const list = listLink.nextElementSibling

        listLink.classList.toggle('is-active')
        list.classList.toggle('is-active')
    }

    equalHeight() {
        const elements = document.getElementsByClassName("equal-js");
        let heights = 0;

        Array.prototype.forEach.call(elements, item => {
            heights = (item.offsetHeight > heights) ? item.offsetHeight : heights;
        });

        Array.prototype.forEach.call(elements, item => {
            item.style.height = heights + "px";
        });

    }
}

const app = new App()

class Accordion {
    constructor(heading) {
        this.heading = heading;
    }

    accordion() {
        const accordionHeading = document.querySelectorAll(this.heading);

        accordionHeading.forEach((item, key) => {
            item.addEventListener('click', () => {
                accordionHeading.forEach(element => {
                    element.classList.contains('active') ?
                        element.classList.remove('active') : null;
                });

                item.classList.contains('active') ?
                    item.classList.remove('active') : item.classList.add('active');
            });
        });
    }

    showOne() {
        const accordionHeading = document.querySelectorAll(this.heading);

        accordionHeading.forEach((item, key) => {
            item.addEventListener('click', () => {

                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    item.nextElementSibling.style.maxHeight = '0px';
                } else {
                    item.classList.add('active');
                    item.nextElementSibling.style.maxHeight = '1000px';
                }
            });
        });
    }
}

const collapsible = new Accordion('.accordion-panel');
// for open every use showAll();
collapsible.showOne();


class CardsList {

    showAll() {
        if (!document.getElementById("list_row_cards")) return;
        const container = document.getElementById("list_row_cards");
        const cards = container.getElementsByClassName("card-row");
        const button = document.getElementById("show_more_rows");
        let two_cards = 0;
        let all_height = 0;


        Array.prototype.forEach.call(cards, (item, i) => {
            two_cards += i < 2 ? item.offsetHeight : 0;
            all_height += item.offsetHeight;
        });

        container.style.maxHeight = two_cards + "px";

        button.addEventListener('click', () => {
            if (container.classList.contains("active")) {
                button.innerHTML = "show all +";
                container.style.maxHeight = two_cards + "px";
                container.classList.remove("active");
            } else {
                button.innerHTML = "hide all -";
                container.style.maxHeight = all_height + "px";
                container.classList.add("active");
            }
        });
    }
}

const careersCardsList = new CardsList();
careersCardsList.showAll();

class videoContainer {
    playPause() {
        if (!document.getElementById("media-player")) return;
        const video = document.getElementById("media-video");
        const controls = document.getElementById("media-controls");
        const img_bg = controls.querySelector(".video-bg");
        let is_playing = false;

        video.addEventListener("playing", () => {
            is_playing = true;
        });

        video.addEventListener("pause", () => {
            is_playing = false;
        });

        controls.addEventListener('click', () => {
            if (is_playing) {
                video.pause();
                controls.classList.remove('playing');
            } else {
                video.play();
                controls.classList.add('playing');
                img_bg.classList.add("d-none");
            }
        });

    }
}

const videoPlay = new videoContainer();
videoPlay.playPause();


class buildEvents {
    constructor() {
        this.dates_container = document.getElementById("dates_row");
        this.days_names = ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"];
        this.months_full = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }


    createDatesRow(first_day) {
        this.dates_container.innerHTML = '';
        let start_day = first_day;

        for (let i = 0; i < this.days_names.length; i++) {
            const date_el = document.createElement('button');
            const date_el_top = document.createElement('span');
            const date_el_bottom = document.createElement('span');
            const date_el_marker = document.createElement('span');

            date_el_top.classList.add("pickup-date__top");
            date_el_bottom.classList.add("pickup-date__bottom");

            date_el.setAttribute("type", "button");
            date_el.classList.add("pickup-date");
            date_el.appendChild(date_el_top);
            date_el.appendChild(date_el_bottom);

            let counter = start_day.getTime() + 86400000 * i,
                new_day = new Date(counter),
                d = new_day.getDate().toString();

            date_el_bottom.innerHTML = "<span>" + this.days_names[i] + "</span><span>" + d + "</span>";
            this.dates_container.appendChild(date_el);

            // date_el_top.appendChild(date_el_marker);

        }
        const temp_first_day = first_day;
        this.addDateAttribute(temp_first_day);
    }

    getTodayRow() {
        if (!document.getElementById("get_today_btn")) return;
        const btn = document.getElementById("get_today_btn");
        btn.addEventListener("click", () => this.initialWeek());
    }

    initialWeek() {
        if (!document.getElementById("btn_pick_month")) return;
        const date = new Date();
        let diff = date.getDate() - date.getDay();
        let start_day = new Date(date.setDate(diff));
        let start_day_num = start_day.getDate();
        const day_span = document.getElementById("btn_pick_month");
        const year_span = document.getElementById("btn_pick_year");

        day_span.innerHTML = '';
        year_span.innerHTML = '';
        day_span.innerText = this.months_full[date.getMonth()];
        year_span.innerText = date.getFullYear().toString();

        this.createDatesRow(start_day);
        if (initial_celendar !== undefined) {
            updateEvents(jQuery('.pickup-date:first').attr('data-date'), jQuery('.pickup-date:last').attr('data-date'));
        }
    }

    changeDropdown(date) {
        const day_span = document.getElementById("btn_pick_month");
        const year_span = document.getElementById("btn_pick_year");

        day_span.innerHTML = '';
        year_span.innerHTML = '';
        day_span.innerText = this.months_full[date.getMonth()];
        year_span.innerText = date.getFullYear().toString();
    }

    addDateAttribute(first_day) {
        const buttons = document.getElementsByClassName("pickup-date");
        const start_day = first_day;

        this.days_names.forEach((elem, i) => {
            const item = buttons[i];
            let counter = start_day.getTime() + 86400000 * i,
                new_day = new Date(counter),
                y = new_day.getFullYear().toString(),
                m = (new_day.getMonth() + 1).toString(),
                d = new_day.getDate().toString();
            if (m <= 9) m = '0' + m;
            if (d <= 9) d = '0' + d;
            item.setAttribute("data-date", "" + y + "" + m + "" + d);
        });
    }

    getAllWeek(first, week_number) {
        const first_day = first;
        this.createDatesRow(first_day);
        //this.addDateAttribute(first_day);
    }
}

const events = new buildEvents();
events.initialWeek();
events.getTodayRow();

function getCurrentEvent(date) {
    console.log(date);
    $.ajax({
        data: {
            'event_get': 'get',
            'date_from': date,
            'date_to': date
        },
        type: 'POST',
        url: window.WP_template + '/ajax-handler.php',
        cache: false,
        beforeSend: function () {

        },
        success: function (html) {
            console.log(html);
            html = JSON.parse(html);
            jQuery("#events_row").html(html.data);
        },
        error: function (e) {
            console.log(e.responseText);
        }
    })
}

function updateEvents(date_from, date_to) {
    $.ajax({
        data: {
            'event_get': 'get',
            'date_from': date_from,
            'date_to': date_to
        },
        type: 'POST',
        url: window.WP_template + '/ajax-handler.php',
        cache: false,
        beforeSend: function () {

        },
        success: function (html) {
            html = JSON.parse(html);
            jQuery("#events_row").html(html.data);
            jQuery('.pickup-date').each(function (i, e) {
                if (html.events[$(e).attr('data-date')] !== undefined) {
                    for (let i = 0; i < html.events[$(e).attr('data-date')]; i++) {
                        jQuery(e).find('.pickup-date__top').append('<span></span>');
                    }
                }
            });
        },
        error: function (e) {
            console.log(e.responseText);
        }
    })
}

jQuery('body').on('click', '.pickup-date', function () {
    getCurrentEvent($(this).attr('data-date'));
});

window.onload = function () {
    if(jQuery('.pickup-date:first').length > 0){
        updateEvents(jQuery('.pickup-date:first').attr('data-date'), jQuery('.pickup-date:last').attr('data-date'));
        initial_celendar = true;
    }

};


const fp = flatpickr("#datePicker", {
    "disableMobile": true,
    "plugins": [new weekSelectPlugin({})],
    "onChange": [function () {
        // extract the week number
        // note: "this" is bound to the flatpickr instance
        const weekNumber = this.selectedDates[0]
            ? this.config.getWeek(this.selectedDates[0])
            : null;

        console.log(this);
        events.getAllWeek(this.weekStartDay, weekNumber);
        events.changeDropdown(this.selectedDates[0]);
        console.log(this.weekStartDay);
        updateEvents(jQuery('.pickup-date:first').attr('data-date'), jQuery('.pickup-date:last').attr('data-date'));
    }]
});
//https://flatpickr.js.org/plugins/#weekselect

jQuery('body').on('click', '#home_video_button', function () {
    var movie = $("#home_video");
    $("body").toggleClass("noscroll");
    $(".video-modal").toggleClass("show-modal");
    movie[0].play();
});
jQuery('body').on('click', '.video-modal', function (event) {
    var movie = $("#home_video");
    if (event.target == movie[0]) return;
    $(this).toggleClass("show-modal");
    movie[0].load();
});