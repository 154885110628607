export const settings = {
  sliders: {
    partnersSlider: {
      navigation: {
        nextEl: '.slider-button--next',
        prevEl: '.slider-button--prev'
      },
      slidesPerView: 1
      // spaceBetween: 30
      // breakpoints: {
      //   320: {
      //     slidesPerView: 1
      //   },
      //   768: {
      //     slidesPerView: 2,
      //     spaceBetween: 20
      //   }
      // },
      // pagination: {
      //   el: '.slider-pagination--homeNewsSlider',
      //   type: 'bullets'
      // }
    }
  },
  defaultForm: {
    classTo: 'page-input',
    errorClass: 'has-danger',
    successClass: 'has-success',
    errorTextParent: 'page-input',
    errorTextTag: 'div',
    errorTextClass: 'text-help'
  }
}
