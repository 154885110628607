import axios from 'axios'

export function inputsHighlight () {
    let inputs = document.querySelectorAll('input, textarea')
    let toggleHighlight = el =>
        el.parentNode.classList.toggle('has-focus', el.value.length > 0)

    inputs.forEach(el => {
        if (el.required) el.parentNode.classList.add('page-input--required')
        if (el.disabled) el.parentNode.classList.add('page-input--disabled')

        toggleHighlight(el)

        el.addEventListener('focus', e =>
            e.currentTarget.parentNode.classList.add('has-focus')
        )
        ;['blur', 'input'].forEach(ev => {
            el.addEventListener(ev, e => toggleHighlight(e.currentTarget))
        })
    })
}

export function getScrollbarWidth () {
    let outer = document.createElement('div')
    let inner = document.createElement('div')

    outer.style.visibility = 'hidden'
    outer.style.width = '100px'
    inner.style.width = '100%'

    outer.appendChild(inner)
    document.body.appendChild(outer)

    let widthWithoutScrollbar = outer.offsetWidth

    outer.style.overflow = 'scroll'

    let widthWithScrollbar = inner.offsetWidth

    document.body.removeChild(outer)

    return widthWithoutScrollbar - widthWithScrollbar
}

export function blockGlobalScroll () {
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowY = 'hidden'
    body.style.paddingRight = getScrollbarWidth() + 'px'
}

export function unBlockGlobalScroll () {
    let body = document.getElementsByTagName('body')[0]
    body.style.overflowY = 'auto'
    body.style.paddingRight = 0
}

export function loadSvgSprite () {
    const requireAllSvg = r => r.keys().forEach(r)
    requireAllSvg(require.context('../sprite-source/', true, /\.svg$/))

    axios
        .get('/dist/svg/icons.svg')
        .then(r => (document.getElementById('svg-icons').innerHTML = r.data))
        .catch(() => console.warn('No Sprites Found'))
}

